import React from "react";
import styled, { css } from "styled-components";
import { Helmet } from "react-helmet";

import { Header } from "./Header";
import { Footer } from "./Footer";
import { Theme } from "../theme";

export const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <script src="https://cdn.jsdelivr.net/npm/focus-visible@5.0.2/dist/focus-visible.min.js"></script>
      </Helmet>
      <Theme>
        <Site>
          <Header />
          {children}
          <Footer />
        </Site>
      </Theme>
    </>
  );
};

export const Site = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  > ${Header} {
    flex: 0 0 auto;
  }

  > ${Footer} {
    flex: 0 0 auto;
  }

  > * {
    flex: 1 0 auto;
  }

  /* ${props =>
    props.theme.hero.parallax &&
    css`
      overflow-y: auto;
      overflow-x: auto;
      perspective: 1px;
      perspective-origin: top;
    `} */
`;
