import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";

import { Wrapper } from "../style";

export const Footer = styled(({ ...styleProps }) => {
  return (
    <footer {...styleProps}>
      <Wrapper>© {new Date().getFullYear()} Takiwā</Wrapper>
    </footer>
  );
})`
  font-size: 0.8rem;
  line-height: 3rem;
  text-align: center;
  height: 3rem;
  background-color: ${props =>
    transparentize(0.97, props.theme.color.foreground)};
  box-shadow: inset 0 1px 0
    ${props => transparentize(0.95, props.theme.color.foreground)};
`;
