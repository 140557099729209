import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import { Helmet } from "react-helmet";

import { Wrapper, Main, Paper } from "../style";
import { Hero } from "./Hero";

const defaultBackgroundUrl =
  "https://storage.googleapis.com/takiwa-gatsby-landing-images/hero_bg_blue_globe.jpg";

const defaultBackgroundPosition = "50% 50%";

export const Page = ({
  children,
  pageTitle,
  pageDescription,
  heroHeadline,
  heroTextline,
  backgroundUrl,
  backgroundPosition,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl
            image
          }
        }
      }
    `
  );

  const { pathname } = useLocation();

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    image,
  } = data.site.siteMetadata;

  const seo = {
    title: pageTitle || defaultTitle,
    description: pageDescription || defaultDescription,
    image: `${siteUrl}${image}`,
    url: `${siteUrl}${pathname}`,
  };

  return (
    <>
      <Helmet title={seo.title} titleTemplate={titleTemplate}>
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        {seo.url && <meta property="og:url" content={seo.url} />}
        {seo.title && <meta property="og:title" content={seo.title} />}
        {seo.description && (
          <meta property="og:description" content={seo.description} />
        )}
        {seo.image && <meta property="og:image" content={seo.image} />}
      </Helmet>
      <Hero
        headline={heroHeadline}
        textline={heroTextline}
        backgroundUrl={backgroundUrl || defaultBackgroundUrl}
        backgroundPosition={backgroundPosition || defaultBackgroundPosition}
        overlay
      />
      <Main>
        <Wrapper>
          <Paper>{children}</Paper>
        </Wrapper>
      </Main>
    </>
  );
};
