import React from "react";
import styled, { css } from "styled-components";
import { transparentize } from "polished";

import { Wrapper, Overlay } from "../style";

export const Hero = ({
  headline,
  textline,
  backgroundUrl,
  backgroundPosition,
  large,
}) => {
  return (
    <HeroWrapper>
      <HeroBackground>
        <HeroImage url={backgroundUrl} backgroundPosition={backgroundPosition}>
          <HeroOverlay />
        </HeroImage>
      </HeroBackground>
      {(headline || textline || true) && (
        <HeroContent large={large}>
          <Wrapper>
            {headline && <Headline>{headline}</Headline>}
            {textline && <Textline>{textline}</Textline>}
          </Wrapper>
        </HeroContent>
      )}
    </HeroWrapper>
  );
};

const HeroWrapper = styled.div`
  position: relative;
  flex: 0 0 auto;
  top: 0;
  padding-top: ${props => props.theme.header.height};
  min-height: calc(
    ${props => props.theme.header.height} +
      ${props => props.theme.header.height}
  );

  /* ${props =>
    props.theme.hero.parallax &&
    css`
      transform-style: preserve-3d;
    `} */
`;

const HeroContent = styled.div`
  display: block;
  padding: 3rem 0;

  ${props =>
    props.large &&
    css`
      padding: 8rem 0;
    `}
`;

const HeroBackground = styled.div`
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: -${props => props.theme.hero.overlap};
  z-index: -1;
  background-color: ${props => transparentize(0.1, props.theme.color.primary)};
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 0;
  overflow: hidden;
  ${Overlay} {
    z-index: 1;
  }

  /* ${props =>
    props.theme.hero.parallax &&
    css`
      transform-style: preserve-3d;
      transform: translateZ(-1px) scale(2) translateY(25%);
    `} */
`;

export const Headline = styled.h2`
  font-size: 2.6em;
  line-height: 1.2;
  color: ${props => props.theme.color.white};
  word-spacing: 1px;
  font-weight: 700;
  text-transform: none;
`;

export const Textline = styled.p`
  font-size: 1.3rem;
  line-height: 1.2;
  color: ${props => props.theme.color.secondary};
  word-spacing: 1px;
  font-weight: 500;
  text-transform: none;
  padding-bottom: 0.3rem;
`;

export const Actions = styled.div`
  padding-bottom: 0.5rem;
  > * {
    margin-right: 1rem;
  }
`;

const HeroImage = styled.div`
  height: 100vh;
  width: 100vw;
  position: relative;
  background-size: cover;
  background-position: ${props => props.backgroundPosition};
  background-image: url("${props => props.url}");
  @media (max-width: ${props => props.theme.breakpoints.large}) {
    background-position: 50% 50%;
  }
`;

const HeroOverlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
