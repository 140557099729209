import React, { useState } from "react";
import { mix } from "polished";
import { ThemeProvider } from "styled-components";
import { GlobalStyles, bestContrast } from "./style";
import { createGlobalStyle } from "styled-components";

const globalTheme = {
  color: {
    black: "#131110",
    white: "#f7f7f7",
    primary: "#004970",
    secondary: "#28c6e1",
  },
  easing: "cubic-bezier(0.215, 0.610, 0.355, 1.000)",
  breakpoints: {
    small: "600px",
    medium: "1200px",
    large: "1600px",
    huge: "2200px",
  },
  radius: {
    small: "3px",
  },
  header: {
    overline: true,
    transparent: true,
    height: "3.25rem",
    underline: true,
  },
  hero: {
    image: "./images/cafe.jpg",
    large: false,
    overlay: true,
    overlap: "8rem",
    parallax: true,
  },
  typography: {
    uppercaseH2: false,
  },
  menu: {
    style: "glow",
  },
};

export const ThemeContext = React.createContext();

export const Theme = ({ children }) => {
  // const isBrowser = typeof window !== "undefined"
  // const userPrefDark = isBrowser ? localStorage.getItem("isDarkMode") : false
  const initialDarkMode = true;

  const [darkMode, setDarkMode] = useState(initialDarkMode);

  const toggleDarkMode = () => {
    const newMode = !darkMode;

    setDarkMode(newMode);

    if (typeof window !== "undefined") {
      localStorage.setItem("isDarkMode", newMode);
    }
  };

  const theme = {
    isDarkMode: darkMode,
    color: {
      black: darkMode ? globalTheme.color.black : globalTheme.color.black,
      white: darkMode ? globalTheme.color.white : globalTheme.color.white,
      primary: globalTheme.color.primary,
      primaryContrast: bestContrast(
        globalTheme.color.primary,
        globalTheme.color.white,
        globalTheme.color.black
      ),
      secondary: globalTheme.color.secondary,
      secondaryContrast: bestContrast(
        globalTheme.color.secondary,
        globalTheme.color.white,
        globalTheme.color.black
      ),
      foreground: darkMode
        ? mix(0.8, globalTheme.color.white, globalTheme.color.black)
        : globalTheme.color.black,
      background: darkMode ? globalTheme.color.black : globalTheme.color.white,
      link: bestContrast(
        darkMode ? globalTheme.color.black : globalTheme.color.white,
        globalTheme.color.primary,
        globalTheme.color.secondary
      ),
    },
    easing: globalTheme.easing,
    breakpoints: globalTheme.breakpoints,
    radius: globalTheme.radius,
    header: globalTheme.header,
    menu: globalTheme.menu,
    hero: globalTheme.hero,
    typography: globalTheme.typography,
  };

  return (
    <ThemeContext.Provider
      value={{
        theme: theme,
        toggleDarkMode: toggleDarkMode,
        isDarkMode: darkMode,
      }}
    >
      <ThemeContext.Consumer>
        {({ theme }) => (
          <ThemeProvider theme={theme}>
            <>
              <TinaOverrideGlobalStyle primary={theme.color.primary} />
              <GlobalStyles />
              {children}
            </>
          </ThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  );
};

const TinaOverrideGlobalStyle = createGlobalStyle`
  :root {
    --tina-color-primary-light: ${props => props.primary};
    --tina-color-primary: ${props => props.primary};
    --tina-color-primary-dark: ${props => props.primary};
  }
`;
